<script>
/**
 * Navbar component
 */
export default {
  data() {
    return {
      isCondensed: false,
      backendUrl: process.env.VUE_APP_BACKEND_URL,
    };
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
    isIcons: {
      type: Boolean,
    },
  },
  computed: {
    logoLight() {
      return !(this.$store.state.style.theme === 'light' ?? this.navLight);
    }
  },

  mounted() {
    window.onscroll = function () {
      onwindowScroll();
    };

    function onwindowScroll() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById("topnav").classList.add("nav-sticky");
      } else {
        document.getElementById("topnav").classList.remove("nav-sticky");
      }

      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        document.getElementById("back-to-top").style.display = "inline";
      } else {
        document.getElementById("back-to-top").style.display = "none";
      }
    }

    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add("active");
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },

    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;

      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    },
  },
};
</script>

<template>
  <div>
    <!-- Navbar STart -->
    <header
      id="topnav"
      class="defaultscroll sticky"
      :class="{ 'bg-white': isWhiteNavbar === true }"
    >
      <div class="container">
        <!-- Logo container-->
        <div>
          <router-link class="logo" to="/" v-if="logoLight">
            <img src="images/logo-light.png" class="logo-image" alt="" />
          </router-link>
          <router-link class="logo" to="/" v-else>
            <img src="images/logo-dark.png" class="l-dark logo-image" alt="" />
            <img
              src="images/logo-dark.png"
              class="l-light logo-image"
              alt=""
            />
          </router-link>
        </div>
        <div class="buy-button" v-if="isIcons !== true">
          <router-link
              class="btn box-shadow"
              :class="navLight ? 'btn-light' :  'btn-primary'"
              to="/buy"
           >
            Buy Now
          </router-link>
          <a
              :href="backendUrl"
              target="_blank"
              class="btn ml-2 box-shadow bg-dark-purple"
              :class="navLight ? 'btn-light' :  'btn-primary'"
          >Login</a
          >
        </div>
        <ul class="buy-button list-inline mb-0" v-if="isIcons === true">
          <li class="list-inline-item mb-0 developer-icon">
            <b-dropdown
              right
              variant="link"
              toggle-class="text-decoration-none p-0 pr-2"
              menu-class="dd-menu dropdown-menu-right bg-white shadow rounded border-0 mt-3 py-0"
            >
              <template #button-content>
                <i class="mdi mdi-magnify h4 text-muted"></i>
              </template>
              <div style="width: 300px">
                <form>
                  <input
                    type="text"
                    id="text"
                    name="name"
                    class="form-control border bg-white"
                    placeholder="Search..."
                  />
                </form>
              </div>
            </b-dropdown>
          </li>
          <li class="list-inline-item mb-0 pr-2">
            <a href="#" class="btn btn-icon btn-soft-primary"
              ><i class="mdi mdi-github mdi-18px icons"></i
            ></a>
          </li>
          <li class="list-inline-item mb-0 pr-2">
            <a href="#" class="btn btn-icon btn-soft-primary"
              ><i class="mdi mdi-stack-overflow mdi-18px icons"></i
            ></a>
          </li>
          <li class="list-inline-item mb-0">
            <a
              href="javascript:void(0)"
              class="btn btn-icon btn-soft-primary"
              data-toggle="modal"
              data-target="#productview"
              ><i class="mdi mdi-account-outline mdi-18px icons"></i
            ></a>
          </li>
        </ul>
        <!--end login button-->
        <!--end login button-->
        <!-- End Logo container-->
        <div class="menu-extras">
          <div class="menu-item">
            <!-- Mobile menu toggle-->
            <a
              class="navbar-toggle"
              @click="toggleMenu()"
              :class="{ open: isCondensed === true }"
            >
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </div>
        </div>

        <div id="navigation">
          <!-- Navigation Menu-->
          <ul
            class="navigation-menu"
            :class="{ 'nav-light': navLight === true }"
          >
            <li>
              <router-link to="/#home" class="side-nav-link-ref">Home</router-link>
            </li>
            <li>
              <router-link to="/#Shader" class="side-nav-link-ref">Shader</router-link>
            </li>
	          <li>
		          <router-link to="/#WR" class="side-nav-link-ref">Room&nbsp;Q</router-link>
	          </li>
            <li>
              <router-link to="/#2FA" class="side-nav-link-ref">2FA</router-link>
            </li>
            <li>
              <router-link to="/#Pricing" class="side-nav-link-ref">Pricing</router-link>
            </li>
            <li>
              <router-link to="/support" target="_blank" class="side-nav-link-ref">Support</router-link>
            </li>
            <!--<li>
              <router-link to="/contact" class="side-nav-link-ref">Contact us</router-link>
            </li>
            <li>
              <router-link to="/faqs" class="side-nav-link-ref">FAQs</router-link>
            </li>-->
            <!-- Group example
            <li class="has-submenu">
              <a href="javascript:void(0)" @click="onMenuClick">Landing</a
              ><span class="menu-arrow"></span>
              <ul class="submenu megamenu">
                <li>
                  <ul>
                    <li>
                      <router-link to="/index-saas" class="side-nav-link-ref"
                        >Saas</router-link
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            -->
          </ul>
          <!--end navigation menu-->
          <div class="buy-menu-btn d-none">
            <router-link
                class="btn box-shadow"
                :class="navLight ? 'btn-light' :  'btn-primary'"
                to="/buy"
            >
              Buy Now
            </router-link>
            <a
                :href="backendUrl"
                target="_blank"
                class="btn ml-2 box-shadow bg-dark-purple"
                :class="navLight ? 'btn-light' :  'btn-primary'"
            >Login</a
            >
          </div>
          <!--end login button-->
        </div>
        <!--end navigation-->
      </div>
      <!--end container-->
    </header>
    <!--end header-->
    <!-- Navbar End -->
  </div>
</template>

<style scoped>
.box-shadow {
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.35);
}
.bg-dark-purple {
  background-color: #11001B !important;
  border-color: #11001B !important;
}
.logo-image {
  height: 35px;
}
@media (max-width: 500px) {
  .logo-image {
    height: 15px;
  }
}
</style>
