<script>
export default {
  data() {
    return {};
  }
};
</script>

<template>
  <div>
    <!-- Footer Start -->
    <footer class="footer">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-3">
            <a href="#" class="logo-footer">
              <img src="images/logo-light.png" height="24" alt="" />
            </a>
          </div>
          <!--end col-->

          <div class="col-8 col-md-6 d-md-flex text-right justify-content-center">
            <ul class="list-unstyled footer-list">
              <li>
                <router-link to="/privacypolicy" class="text-foot">
                  Privacy Policy
                </router-link>
              </li>
            </ul>
          </div>
          <!--end col-->


        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
  </div>
</template>
